import * as React from 'react';
import {ISantaProps, IHostProps} from '@wix/native-components-infra/dist/es/src/types/types';
import {IPropsInjectedByViewerScript} from '../types/app-types';

const PropsContext = React.createContext<IProvidedGlobalProps>(undefined);

export interface IProvidedGlobalProps {
  globals?: GlobalProps;
}

export type GlobalProps = Pick<
  IPropsInjectedByViewerScript & ISantaProps,
  // tslint:disable-next-line:max-union-size
  | 'isSEO'
  | 'texts'
  | 'product'
  | 'isInteractive'
  | 'shouldFocusAddToCartButton'
  | 'handleAddToCart'
  | 'widgetPreset'
  | 'navigate'
  | 'productPageUrl'
  | 'isMobile'
  | 'horizontalLayout'
  | 'experiments'
> & // tslint:disable-next-line:max-union-size
  Pick<IHostProps, 'dimensions' | 'appLoadBI' | 'style'>;

export function withGlobalPropsProvider(Component) {
  return (props: IPropsInjectedByViewerScript & ISantaProps & {style: IHostProps['style']}) => {
    const {
      texts,
      isSEO,
      product,
      style,
      isInteractive,
      handleAddToCart,
      shouldFocusAddToCartButton,
      navigate,
      widgetPreset,
      productPageUrl,
      isMobile,
      horizontalLayout,
      experiments,
      ...passThroughProps
    } = props;
    const {dimensions, appLoadBI} = props.host;
    return (
      <PropsContext.Provider
        value={{
          globals: {
            appLoadBI,
            texts,
            isSEO,
            style,
            shouldFocusAddToCartButton,
            product,
            isInteractive,
            handleAddToCart,
            widgetPreset,
            navigate,
            dimensions,
            productPageUrl,
            isMobile,
            horizontalLayout,
            experiments,
          },
        }}>
        <Component {...passThroughProps} />
      </PropsContext.Provider>
    );
  };
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  return props => {
    return <PropsContext.Consumer>{globalProps => <Component {...globalProps} {...props} />}</PropsContext.Consumer>;
  };
}
